import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const StoragePage = () => {
  return (
    <Layout pageTitle="Storage">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../media/storage_hero.jpeg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Storage</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section">
          <Row className="text-center">
            <Col>
              <h3>WESSUC IS HAPPIER THAN A PIG IN ... MUD. Wessuc is excited to announce our application for NASM storage in Sarnia, Ontario has been approved.</h3>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <h5>Approved Waste</h5>
              <p>
                <ul>
                  <li>Pulp and paper biosolids</li>
                  <li>Municipal sewage biosolids</li>
                  <li>Yard waste, fruit and vegetable peels</li>
                  <li>Food processing waste</li>
                  <li>Washwater from food processing facilities</li>
                  <li>Material not from an agricultural source that can be land applied as a nutrient.</li>
                  <li>Anaerobic Digester digestate</li>
                  <li>Manure and bedding from non-farm herbivorous animals</li>
                  <li className="bold-1">AND MORE</li>
                </ul>
              </p>
            </Col>
            <Col>
              <p>So what does this mean...?</p>
              <p>
                We now have more capacity to serve you by managing your Non-Agricultural Sourced Materials (NASM)
              </p>
                <Button href='/contact' variant='primary' className="shadow">CONTACT US TODAY FOR YOUR STORAGE NEEDS</Button>
            </Col>
          </Row>
        </Container>
      </main>
      <MustRead />
    </Layout>
  )
}

export default StoragePage